.home-photo{
    height: 600px;
    max-width: 100%; /* The image will scale down if it's wider than the container */
    padding: 20px;
    
}


.home-container{
    border-radius: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 600px;
}

.home-container button{
    width: 300px;
}


.banner-thumb img {
    height: auto;
    max-width: 100%;
  }


.banner-section {
    padding-top: 50px;
}


.banner-section .banner-thumb {
    margin-bottom: 30px;
}

.section-header{
    text-align: center;
    margin-bottom: 50px;
    position: relative;
}
.work-section{
    text-align: center;
}
.padding-tb {
    padding: 60px 0;
}

.members-buttons{
    text-align: center;
    margin-top: 50px;
}

.lab-btn{
    padding: 16px;
    text-decoration: none !important;
    border-radius: 2px 0 0 2px;
    font-size: 18px;
}

.lab-btn:hover {
    background-color: var(--main-link-button-color);
    color: #fff;
    box-shadow: 3px 3px 0 currentColor;
}

.clints-section .section-wrapper {
    height: 500px;
    background: url(../images/map.png);
    background-size: cover;
}
.clints-section .section-wrapper .clients .client-list{
    content:"";
    cursor: pointer;
}

.clints-section .section-wrapper .clients .client-list .client-thumb::before {
    display: inline-block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: #0ca5f1;
}

.clints-section .section-wrapper .clients .client-list:nth-child(1) {
    top: 110px;
    left: 5%;
}
.clints-section .section-wrapper .clients .client-list:nth-child(2) {
    top: 40px;
    left: 35%;
}
.clints-section .section-wrapper .clients .client-list:nth-child(3) {
    top: 110px;
    left: 75%;
}
.clints-section .section-wrapper .clients .client-list:nth-child(4) {
    top: 420px;
    left: 30%;
}
.clints-section .section-wrapper .clients .client-list:nth-child(5) {
    top: 300px;
    left: 47%;
}
.clints-section .section-wrapper .clients .client-list:nth-child(6) {
    top: 408px;
    left: 80%;
}



@media (min-width: 992px)
{
    .padding-tb {
        padding: 10px 0;
    }
    .work-section .section-wrapper .col-lg-4:not(:last-child) .work-item:after {
        position: absolute;
        content: "";
        top: 50px;
        right: -90px;
        width: 145px;
        height: 40px;
        background: url(../images/work/arrow.png);
    }



}
@media (max-width: 992px)
{
    .big-form img{
        display: none;

    }

    .big-form .photo-content{
        display: none;
    }
    
    .big-form .login-form-new{
        border: none;
    }


}

@media (max-width: 768px)
{

    .home-container{
        width: 95%;
    }
    .home-container .home-photo{
        height: 400px;
    }
    .home-left{
        padding-top: 20px;
    }
}
@media (max-width: 575px)
{
    .clints-section .section-wrapper .clients .client-list {
        position: static !important;
        margin: 15px;
    }

    .clints-section{
        display: none;
    }

    .big-form .login-form-new{
        max-width: 300px;
        padding: 10px;

    }

}

